import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class ProspectListService {
  getProspects() {
    return axios
      .get(`${config["apiUrl"]}crm/prospect`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getSources() {
    return axios
      .get(`${config["apiUrl"]}crm/prospect/source`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getClassification() {
    return axios
      .get(`${config["apiUrl"]}crm/prospect/classification`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getStatus() {
    return axios
      .get(`${config["apiUrl"]}crm/prospect/status`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getProspect(id) {
    return axios
      .get(`${config["apiUrl"]}crm/prospect/${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getSellers() {
    return axios
      .get(`${config["apiUrl"]}crm/prospect/seller`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createProspect(bodyParms) {
    return axios
      .post(`${config["apiUrl"]}crm/prospect`, bodyParms, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        if (error.response.status == 409) {
          console.log("ERROR:: ", error.response.data);
          return error.response;
        }
        console.log("ERROR:: ", error.response.data);
        return null
      });
  }

  updateProspect(prospectId, bodyParms) {
    return axios
      .put(`${config["apiUrl"]}crm/prospect/${prospectId}`, bodyParms, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        if (error.response.status == 409) {
          console.log("ERROR:: ", error.response.data);
          return error.response;
        }
        console.log("ERROR:: ", error.response.data);
        return null
      });
  }
}
export default new ProspectListService();
