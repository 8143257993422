<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Responsável pelo Survey'"
      :modal="true"
      :closable="false"
    >
      <form
        name="SurveyChangeSurveyResponsible"
        class="p-col-12"
        @submit.prevent="saveResponsible"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="responsibleUser"
                :options="listAllUsers"
                :filter="true"
                :optionLabel="getSearchLabel"
                :optionValue="'id'"
                v-model="currentId"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('responsibleUser') },
                  'form-control'
                ]"
              >
              </Dropdown>
              <label for="inputtext">Selecionar Funcionário</label>
            </span>
            <small
              v-if="errors.has('responsibleUser')"
              class="p-error"
              role="alert"
            >
              Funcionário é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveResponsible"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import patsService from "../services/pats.service";
import usersService from "../services/user.service";
import unidecode from "unidecode";
export default {
  name: "SurveyChangeSurveyResponsible",
  props: ["showToast", "show", "currentRespId", "patId"],
  watch: {
    currentRespId() {
      this.$validator.pause();
      this.$validator.reset();
      this.currentId = this.currentRespId;
    }
  },
  data() {
    return {
      currentId: this.currentRespId,
      listAllUsers: []
    };
  },
  created() {
    this.getActiveUsers();
  },
  methods: {
    getSearchLabel(info) {
      return `${unidecode(info.username)}`
    },
    getActiveUsers() {
      return usersService
        .activesUsers()
        .then(response => (this.listAllUsers = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    saveResponsible() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        return patsService
          .setSurveyResponsible(this.patId, this.currentId)
          .then(response => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o responsável pelo survey",
                  detail: "",
                  life: 3000
                });
              }
              return this.$emit("setSurveyResponsible", {
                status: "error",
                currentResponsible: {}
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Responsável pelo survey alterado com sucesso`,
                detail: ``,
                life: 3000
              });
            }
            return this.$emit("setSurveyResponsible", {
              status: "ok",
              currentResponsible: {
                id: response.responsible_user_id,
                name: response.responsible_user,
                office: response.office,
              }
            });
          });
      });
    }
  }
};
</script>
