<template>
  <div class="card p-shadow-6">
    <h1>Surveys - Orçamentos</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <SurveyChangeSurveyResponsible
      :showToast="true"
      :show="patChangeSurveyResponsible.show"
      :patId="patChangeSurveyResponsible.id"
      :currentRespId="patChangeSurveyResponsible.technicianUserId"
      v-on:cancel="changeSurveyResponsibleCancel"
      v-on:setSurveyResponsible="changedSurveyResponsible"
    />
    <SurveyChangeSurveySeller
      :showToast="true"
      :show="patChangeSurveySeller.show"
      :patId="patChangeSurveySeller.id"
      :currentSellerId="patChangeSurveySeller.sellerId"
      v-on:cancel="changeSurveySellerCancel"
      v-on:setSurveySeller="changedSurveySeller"
    />
    <Divider />

    <div class="p-field p-grid">
      <div class="p-col-12 p-md-5 p-mt-1">
        <label for="year-selector">Ano</label>
        <InputNumber
          id="year-selector"
          name="year-selector"
          :value="year"
          class="p-col-10"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          :step="1"
          :max="currentYear"
          :min="minYear"
          :useGrouping="false"
          @input="yearChanged"
        />
      </div>
      <div class="p-col-12 p-md-2 p-mt-1">
        <Button
          label="Todos os Anos"
          style="width: 100%"
          class="p-button-outlined"
          @click="getBudgetPatsAllYears"
        />
      </div>
    </div>
    <DataTable
      scrollHeight="60vh"
      :value="patsList"
      :filters.sync="filters"
      filterDisplay="menu"
      :loading="loading"
      :paginator="true"
      :rows="patsList.length"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} surveys"
      sortField="id"
      :sortOrder="1"
      :reorderableColumns="true"
      @column-reorder="onColReorder"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="p-d-flex">
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Limpar Filtros"
            class="p-button-outlined"
            @click="initFilters()"
          />
          <Button
            type="button"
            icon="pi pi-save"
            label="Gravar Filtro"
            class="p-button-outlined p-ml-2"
            @click="showForm = true"
          />
          <Button
            type="button"
            icon="pi pi-filter"
            label="Os meus Filtros"
            class="p-button-outlined p-ml-2"
            @click="toggleFilters"
            aria:haspopup="true"
            aria-controls="overlay_panel"
          />
          <OverlayPanel
            ref="op"
            appendTo="body"
            :showCloseIcon="true"
            id="overlay_panel"
            style="background: #4d505b"
          >
            <DataTable
              :value="filtersList"
              :filters.sync="filtersFilters"
              filterDisplay="row"
              selectionMode="single"
              class="p-datatable-sm"
              :paginator="true"
              :rows="10"
              @row-select="filterChanged"
              responsiveLayout="scroll"
            >
              <Column field="name" header="Name" sortable filterField="name">
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    class="p-column-filter"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="user_name"
                header="Autor"
                sortable
                filterField="user_name"
              >
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    class="p-column-filter"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column
                field="is_public"
                header="Público"
                sortable
                filterField="is_public"
                dataType="boolean"
              >
                <template #body="slotProps">
                  {{ slotProps.data.is_public ? "Sim" : "Não" }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                  <Dropdown
                    v-model="filterModel.value"
                    @input="filterCallback()"
                    :options="[
                      { value: false, label: 'Não' },
                      { value: true, label: 'Sim' },
                    ]"
                    optionValue="value"
                    optionLabel="label"
                    class="p-column-filter"
                    :showClear="true"
                  >
                  </Dropdown>
                </template>
              </Column>
              <Column :columnKey="'actions'" :reorderableColumn="false">
                <template #body="slotProps">
                  <Button
                    v-tooltip.left="'Aplicar'"
                    icon="pi pi-eye"
                    class="p-button-text p-button-success"
                    @click="filterChanged(slotProps)"
                  />
                  <Button
                    v-if="loggedUser == slotProps.data.user_id"
                    v-tooltip.left="'Apagar'"
                    icon="pi pi-trash"
                    class="p-button-text p-button-danger"
                    @click="confirmDeleteFilter(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </OverlayPanel>
          <span class="p-ml-2 p-mt-2" v-if="nameFilterActive"
            ><b>Filtro Atual:</b> {{ nameFilterActive }}</span
          >
        </div>
      </template>
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column
        sortable
        v-for="col of columns"
        :key="col.field"
        :columnKey="col.field"
        :field="col.field"
        :header="col.header"
        :filterField="col.field"
        :dataType="
          col.dataType == 'date' || col.dataType == 'boolean'
            ? col.dataType
            : 'text'
        "
      >
        <template #body="slotProps">
          {{
            col.dataType != "boolean"
              ? slotProps.data[col.valueKey]
              : slotProps.data[col.valueKey] == true
              ? "Sim"
              : "Não"
          }}
        </template>

        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-if="col.field == 'to_do'"
            v-model="filterModel.value"
            @input="filterCallback()"
            @change="removelocalStorage()"
            :options="problemsList"
            class="p-column-filter"
            :showClear="true"
          />
          <Dropdown
            v-else-if="col.dataType == 'boolean'"
            v-model="filterModel.value"
            @input="filterCallback()"
            @change="removelocalStorage()"
            :options="[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          />
          <v-date-picker
            v-else-if="col.dataType == 'date'"
            v-model="filterModel.value"
            @input="filterCallback()"
            @input.capture="removelocalStorage()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
                @input="removelocalStorage()"
              />
            </template>
          </v-date-picker>
          <InputText
            v-else
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
            @keyup="removelocalStorage()"
          />
        </template>
      </Column>
      <Column
        :headerStyle="{ width: '150px' }"
        :columnKey="'actions'"
        :reorderableColumn="false"
        v-if="columns"
      >
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver informação do Pat'"
            icon="pi pi-eye"
            class="p-button-rounded p-button-primary p-button-outlined p-mr-2"
            @click="viewPatInfo(slotProps.data.id)"
          />
          <Button
            v-if="
              slotProps.data.to_do == 'Por realizar' ||
              slotProps.data.to_do == 'Por marcar' ||
              slotProps.data.to_do == 'Marcado'
            "
            v-tooltip.left="'Alterar data do survey'"
            icon="pi pi-calendar"
            class="p-button-rounded p-button-help p-button-outlined p-mr-2"
            @click="changeSurveyDate(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.to_do == 'Por realizar'"
            v-tooltip.left="'Marcar como Realizado'"
            icon="pi pi-check"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="confirmRealizedPat(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.to_do == 'Sem relatório'"
            v-tooltip.left="'Inserir Relatório'"
            icon="pi pi-upload"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="showUploadForm(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.to_do == 'Por concluir'"
            v-tooltip.left="'Concluir Survey'"
            icon="pi pi-lock"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="confirmClosePat(slotProps.data)"
          />
          <Button
            v-if="!slotProps.data.budgeted"
            v-tooltip.left="'Trocar Técnico Rsponsável pelo Orçamento'"
            icon="pi pi-users"
            class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
            @click="showFormResponsible(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.userToDo && !slotProps.data.budgeted"
            v-tooltip.left="'Marcar como Orçamentado'"
            icon="pi pi-dollar"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="confirmBudget(slotProps.data)"
          />
          <Button
            v-else-if="slotProps.data.userToDo"
            v-tooltip.left="'Marcar como não Orçamentado'"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-outlined p-mr-2"
            @click="confirmBudget(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.status == '1. Em Progresso'"
            v-tooltip.left="'Marcar survey como Pendente'"
            icon="pi pi-clock"
            class="p-button-rounded p-button-warning p-button-outlined p-mr-2"
            @click="confirmSetPending(slotProps.data)"
          />
          <Button
            v-if="slotProps.data.status == '2. Pendente'"
            v-tooltip.left="'Retirar survey de pendente'"
            icon="pi pi-play"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="confirmSetInProgress(slotProps.data)"
          />
          <Button
            v-if="
              user.isEsa ||
              user.isAdmin ||
              user.id == slotProps.data.technicianUserId
            "
            v-tooltip.left="'Trocar Técnico Responsável pelo Survey'"
            icon="pi pi-user-edit"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="changeSurveyResposible(slotProps.data)"
          />
          <Button
            v-if="user.isEsa || user.isAdmin"
            v-tooltip.left="'Trocar Vendedor do Survey'"
            icon="pi pi-briefcase"
            class="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
            @click="changeSurveySeller(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="showForm"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Gravar Filtro'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
      :closable="false"
    >
      <form
        name="missForm"
        class="p-col-12"
        @submit.prevent="saveFilter"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-10 p-md-10">
            <span class="p-float-label p-mt-1">
              <InputText
                type="text"
                :name="'name'"
                v-model="formModel.name"
                v-validate="'required'"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('name'),
                  },
                  'form-control',
                ]"
              />

              <label for="name">Escrever o Nome</label>
            </span>
            <small v-if="errors.has('name')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>
          <div class="p-field p-col-2 p-md-2 p-mt-1">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="public">É Público</label>
            </span>
            <InputSwitch
              name="public"
              v-model="formModel.public"
              class="p-mt-1"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-outlined p-button-success"
          @click="saveFilter"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-outlined p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="showFormChangeResponsible"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Responsável pelo Orçamento'"
      :modal="true"
      :closable="false"
    >
      <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
        <div class="p-field p-col-12 p-md-12 p-mt-4">
          <span class="p-float-label">
            <Dropdown
              name="userToDoOpt"
              :options="activeUsers"
              :filter="true"
              :optionLabel="'username'"
              :optionValue="'id'"
              v-model="patChangeResponsible.userToDoId"
              v-validate="'required'"
              v-bind:class="[
                { 'p-invalid': errors.has('employees') },
                'form-control',
              ]"
            >
            </Dropdown>
            <label for="inputtext">Selecionar Funcionário</label>
          </span>
          <small v-if="errors.has('employees')" class="p-error" role="alert">
            Funcionário é obrigatório
          </small>
        </div>
      </div>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-outlined p-button-success"
          @click="saveResponsible"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-outlined p-button-danger"
          @click="closeFormChangeResponsible"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="showFormChangeStatus"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Colocar Survey como Pendente'"
      :modal="true"
      :closable="false"
    >
      <form
        name="changeTopending"
        class="p-col-12"
        @submit.prevent="savePendingStatus"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <p><b>Pat:</b> {{ patChangeChangeStatus.id }}</p>
            <p><b>Nome:</b> {{ patChangeChangeStatus.name }}</p>
            <span class="p-float-label">
              <Textarea
                name="comment"
                :autoResize="true"
                v-model="patChangeChangeStatus.lastComment"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-error': errors.has('comment') },
                  'p-inputtextarea',
                  'p-inputtext',
                  'p-component',
                ]"
                rows="5"
              />
              <label for="comment">Comentário</label>
            </span>
            <small v-if="errors.has('comment')" class="p-error" role="alert">
              Comentário é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-outlined p-button-success"
          @click="savePendingStatus"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-outlined p-button-danger"
          @click="closeFormChangeStatus"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="showSetDateForm"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar data do Survey'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <b>Pat: {{ patToSetDate.id }} - </b> {{ patToSetDate.name }} |
      {{ patToSetDate.resume }}
      <form
        name="setDateForm"
        class="p-col-12"
        @submit.prevent="setPatDate"
        style="min-height: 15vh"
      >
        <div class="p-d-flex p-jc-center">
          <v-date-picker
            v-model="patToSetDate.date_date"
            :min-date="new Date()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
          </v-date-picker>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setPatDate"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeSetDateForm"
        />
      </template>
    </Dialog>

    <Dialog
      :visible.sync="showFormReport"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Adicionar Relatório'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="addReportForm"
        class="p-col-12"
        @submit.prevent="uploadReportSubmit"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <p><b>Nome do survey: </b>{{ reportToPat.name }}</p>
            <FileUpload
              ref="uploadInput"
              mode="basic"
              name="files[]"
              chooseLabel="Adicionar Relatório"
              v-validate="reportToPat.solution == '' ? 'required' : ''"
            />
          </div>
          <div class="p-field p-col-12 p-md-12">OU</div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label p-mt-1">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="reportToPat.solution"
                v-validate="solutionAreRequired ? 'required' : ''"
                v-bind:class="[
                  {
                    'p-invalid': errors.has('survey-solution'),
                  },
                  'form-control',
                ]"
                :name="'survey-solution'"
              />
              <label for="survey-solution">Escrever Relatório</label>
            </span>
          </div>
          <small
            v-if="errors.has('survey-solution') || errors.has('files[]')"
            class="p-error"
            role="alert"
          >
            É obrigatório anexar um ficheiro OU inserir o relatório manualmente
          </small>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-text p-button-success"
          @click="uploadReportSubmit"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text p-button-danger"
          @click="closeResporForm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import patsService from "../services/pats.service";
import userSettingsService from "../services/userSettings.service";
import usersService from "../services/user.service";
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import { getOnlyDate } from "../helpers/helpers";
import filesService from "../services/files.service";
import SurveyChangeSurveyResponsible from "../components/SurveyChangeSurveyResponsible";
import SurveyChangeSurveySeller from "../components/SurveyChangeSurveySeller";

export default {
  name: "SurveysBudget",
  components: {
    SurveyChangeSurveyResponsible,
    SurveyChangeSurveySeller,
  },
  data() {
    return {
      loading: true,
      patsList: [],
      activeUsers: [],
      year: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      minYear: 2020,
      filters: null,
      columns: null,
      filtersList: [],
      filtersSelected: null,
      showForm: false,
      formModel: { name: null, public: false },
      filtersFilters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        user_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        is_public: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      showFormChangeResponsible: false,
      patChangeResponsible: {},
      showFormChangeStatus: false,
      patChangeChangeStatus: {},
      showSetDateForm: false,
      patToSetDate: {},
      showFormReport: false,
      reportToPat: {},
      patChangeSurveyResponsible: {
        show: false,
        id: null,
        technicianUserId: 0,
      },
      nameFilterActive: null,
      patChangeSurveySeller: { show: false, id: null, sellerId: 0 },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user.id;
    },
    user() {
      return this.$store.state.auth.user;
    },
    problemsList() {
      let problems = [
        "Por marcar",
        "Marcado",
        "Pendente",
        "Por realizar",
        "Sem relatório",
        "Por concluir",
        "Concluido",
      ];

      return problems;
    },
  },
  async beforeCreate() {
    await userSettingsService.getFilter("surveys-budgets").then((response) => {
      return (this.filtersList = response);
    });
    await userSettingsService
      .getUserColumns(this.$store.state.auth.user.id, "surveys-budgets")
      .then((response) => {
        if (response && Object.keys(response).length === 0) {
          return (this.columns = [
            {
              field: "id",
              header: "Pat",
              valueKey: "id",
              dataType: "text",
            },
            {
              field: "name",
              header: "Nome",
              valueKey: "name",
              dataType: "text",
            },
            {
              field: "date_date",
              header: "Data",
              valueKey: "date",
              dataType: "date",
            },
            {
              field: "resume",
              header: "Resumo",
              valueKey: "resume",
              dataType: "text",
            },
            {
              field: "seller",
              header: "Vendedor",
              valueKey: "seller",
              dataType: "text",
            },
            {
              field: "technician",
              header: "Téc. Resp. Survey",
              valueKey: "technician",
              dataType: "text",
            },
            {
              field: "userToDo",
              header: "Téc. Resp. Orçamento",
              valueKey: "userToDo",
              dataType: "text",
            },
            // {
            //   field: "surveyTechnician",
            //   header: "Técnico Survey",
            //   valueKey: "surveyTechnician",
            //   dataType: "text"
            // },
            {
              field: "to_do",
              header: "Estado",
              valueKey: "to_do",
              dataType: "text",
            },
            {
              field: "lastComment",
              header: "Comentário",
              valueKey: "lastComment",
              dataType: "text",
            },
            {
              field: "budgeted",
              header: "Orçamentado",
              valueKey: "budgeted",
              dataType: "boolean",
            },
          ]);
        }
        return (this.columns = JSON.parse(response));
      });
  },
  async created() {
    this.getActiveUsers();
    if (localStorage.budgetActiveFilter) {
      let filtersAux = JSON.parse(localStorage.budgetActiveFilter);
      this.nameFilterActive = filtersAux.name;
      this.filters = JSON.parse(filtersAux.filters);
    } else {
      this.initFilters();
    }
    await this.getBudgetPatsByYear();
  },
  methods: {
    getActiveUsers() {
      return usersService
        .activesUsers()
        .then((response) => (this.activeUsers = response));
    },
    confirmBudget(pat) {
      let message = "";
      let title = "";
      if (pat.budgeted) {
        message = `Tem a certeza que pretende colocar o ${pat.service_type}
        do Pat ${pat.id} - ${pat.name} como NÃO orçamentado?`;
        title = `Colocar survey como NÃO orçamentado?`;
      } else {
        message = `Tem a certeza que pretende colocar o ${pat.service_type}
        do Pat ${pat.id} - ${pat.name} como orçamentado?`;
        title = `Colocar survey como orçamentado?`;
      }
      this.$confirm.require({
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-outlined p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-outlined p-button-danger p-button p-component",
        accept: () => {
          this.budgetPat(pat);
        },
      });
    },
    budgetPat(pat) {
      return patsService.budgetedPat(pat.id).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: `Erro ao mudar o estado do orçamento`,
            detail: "",
            life: 3000,
          });
        }

        pat.budgeted = response.budgeted;

        let detail = "";
        if (pat.budgeted === 0) {
          detail = `${pat.service_type} com o pat ${pat.id}
            mudou o estado para NÃO orçamentado`;
        } else {
          detail = `${pat.service_type} com o pat ${pat.id}
            mudou o estado para orçamentado`;
        }
        return this.$toast.add({
          severity: "success",
          summary: `${pat.service_type} orçamento`,
          detail: detail,
          life: 3000,
        });
      });
    },
    async getBudgetPatsAllYears() {
      this.loading = true;
      this.year = null;
      let userId = this.$store.state.auth.user.id;
      return patsService.getBudgetPats(userId).then((response) => {
        response = response.map((v) => ({
          ...v,
          date: v.date == "1900-01-01" ? "" : v.date,
          date_date: new Date(v.date),
          technician:
            v.firstTask != null ? v.firstTask.technician : v.technician,
        }));
        this.loading = false;
        return (this.patsList = response);
      });
    },
    async getBudgetPatsByYear() {
      this.loading = true;

      let userId = this.$store.state.auth.user.id;
      return patsService
        .getBudgetPatsByYear(userId, this.year)
        .then((response) => {
          response = response.map((v) => ({
            ...v,
            date: v.date == "1900-01-01" ? "" : v.date,
            date_date: new Date(v.date),
            technician:
              v.firstTask != null ? v.firstTask.technician : v.technician,
          }));
          this.loading = false;
          return (this.patsList = response);
        });
    },
    yearChanged(year) {
      if (year < 2020 || year > this.currentYear || year == this.year) {
        return;
      }
      this.year = year;
      return this.getBudgetPatsByYear();
    },
    viewPatInfo(pat) {
      let route = this.$router.resolve({
        path: `/pat-details/${pat}`,
      });
      window.open(route.href, "_blank");
    },
    onColReorder(event) {
      const array_move = (old_index, new_index) => {
        if (new_index >= this.columns.length) {
          var k = new_index - this.columns.length + 1;
          while (k--) {
            this.columns.push(undefined);
          }
        }
        this.columns.splice(new_index, 0, this.columns.splice(old_index, 1)[0]);
      };
      array_move(event.dragIndex, event.dropIndex);

      let bodyParams = {
        user_id: this.$store.state.auth.user.id,
        view: "surveys-budgets",
        columns: JSON.stringify(this.columns),
      };

      userSettingsService.createUpdateColumns(bodyParams).then((response) => {
        if (!response) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao gravar posição das colunas",
            detail: "",
            life: 3000,
          });
        }
      });
    },
    initFilters() {
      if (this.filtersSelected != undefined) {
        this.filtersSelected = null;
      }
      this.removelocalStorage();
      this.nameFilterActive = null;
      this.filters = {
        id: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        date_date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        resume: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        seller: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        technician: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        to_do: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        userToDo: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        lastComment: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        budgeted: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      };
    },
    closeForm() {
      this.formModel.name = null;
      this.formModel.public = false;
      this.showForm = false;
    },
    saveFilter() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let params = {
          user_id: this.$store.state.auth.user.id,
          view: "surveys-budgets",
          name: this.formModel.name,
          is_public: this.formModel.public ? 1 : 0,
          filters: JSON.stringify(this.filters),
        };

        userSettingsService.saveFilter(params).then((response) => {
          if (!response) {
            this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar o filtro",
              detail: "",
              life: 3000,
            });
          }
          this.filtersList.push(response);
          this.nameFilterActive = this.formModel.name;
          this.filtersSelected = response;
          localStorage.budgetActiveFilter = JSON.stringify({
            name: params.name,
            filters: params.filters,
          });
          this.$toast.add({
            severity: "success",
            summary: `Filtro ${this.formModel.name} gravado`,
            detail: ``,
            life: 3000,
          });
          return this.closeForm();
        });
      });
    },
    filterChanged(event) {
      this.$refs.op.hide();
      this.filters = JSON.parse(event.data.filters);
      this.nameFilterActive = event.data.name;
      localStorage.budgetActiveFilter = JSON.stringify({
        name: event.data.name,
        filters: event.data.filters,
      });
      return this.$toast.add({
        severity: "success",
        summary: `Filtro ${event.data.name} foi aplicado`,
        detail: ``,
        life: 3000,
      });
    },
    toggleFilters(event) {
      this.$refs.op.toggle(event);
    },
    confirmDeleteFilter(data) {
      this.$confirm.require({
        header: `Apagar filtro`,
        message: `Deseja apagar o filtro ${data.name}?`,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-outlined p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-outlined p-button-danger p-button p-component",
        accept: () => {
          this.deleFilter(data);
        },
      });
    },
    deleFilter(data) {
      const areYou = (filterAux) => {
        return filterAux.id == data.id;
      };
      userSettingsService.deleteFilter(data.id).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Ocurreu um erro ao eliminar o filtro",
            detail: "",
            life: 3000,
          });
        }
        let filterIndex = this.filtersList.findIndex(areYou);

        if (filterIndex >= 0) {
          this.filtersList.splice(filterIndex, 1);
        }

        return this.$toast.add({
          severity: "success",
          summary: `Filtro ${data.name} eliminado`,
          detail: ``,
          life: 3000,
        });
      });
    },
    closeFormChangeResponsible() {
      this.$validator.pause();
      if (
        this.patChangeResponsible.userToDoIdOri !== undefined &&
        this.patChangeResponsible.userToDoIdOri !=
          this.patChangeResponsible.userToDoId
      ) {
        this.patChangeResponsible.userToDoId =
          this.patChangeResponsible.userToDoIdOri;
      }
      this.patChangeResponsible = {};
      this.showFormChangeResponsible = false;
    },
    showFormResponsible(pat) {
      this.patChangeResponsible = pat;
      let auxPat = { ...pat };
      this.patChangeResponsible.userToDoIdOri = auxPat.userToDoId;
      this.showFormChangeResponsible = true;
    },
    saveResponsible() {
      return patsService
        .setBudgetUserToDo(
          this.patChangeResponsible.id,
          this.patChangeResponsible.userToDoId
        )
        .then((response) => {
          if (!response) {
            this.$toast.add({
              severity: "error",
              summary: "Erro ao alterar o responsável pelo orçamento",
              detail: "",
              life: 3000,
            });
          }
          this.patChangeResponsible.userToDoIdOri =
            this.patChangeResponsible.userToDoId;
          this.patChangeResponsible.userToDo = response.userTodo;
          this.$toast.add({
            severity: "success",
            summary: `Responsável pelo orçamento alterado com sucesso`,
            detail: ``,
            life: 3000,
          });
          return this.closeFormChangeResponsible();
        });
    },
    closeFormChangeStatus() {
      this.$validator.pause();
      this.patChangeChangeStatus.lastComment = null;
      this.patChangeChangeStatus = {};
      this.showFormChangeStatus = false;
    },
    confirmSetPending(pat) {
      this.patChangeChangeStatus = pat;
      this.showFormChangeStatus = true;
    },
    savePendingStatus() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let bodyParams = { message: this.patChangeChangeStatus.lastComment };
        return patsService
          .setPending(this.patChangeChangeStatus.id, bodyParams)
          .then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: `Erro ao mudar o estado do survey`,
                detail: "",
                life: 3000,
              });
            }
            this.patChangeChangeStatus.status = "2. Pendente";
            this.patChangeChangeStatus.to_do = "Pendente";
            this.patChangeChangeStatus.lastComment = response;
            this.$toast.add({
              severity: "success",
              summary: `O survey mudou o estado para pendente`,
              detail: "",
              life: 3000,
            });
            return this.closeFormChangeStatus();
          });
      });
    },
    confirmSetInProgress(pat) {
      let title = `Retirar survey de pendente?`;
      let message = `Tem a certeza que pretende remover o pat ${pat.id} - ${pat.name} do estado de pendente?`;

      this.$confirm.require({
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-outlined p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-outlined p-button-danger p-button p-component",
        accept: () => {
          return patsService.removePending(pat.id).then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: `Erro ao mudar o estado do survey`,
                detail: "",
                life: 3000,
              });
            }
            pat.status = "1. Em Progresso";
            pat.to_do = "Por realizar";
            pat.lastComment = null;
            return this.$toast.add({
              severity: "success",
              summary: `O survey mudou o estado para em progresso`,
              detail: "",
              life: 3000,
            });
          });
        },
      });
    },
    closeSetDateForm() {
      this.showSetDateForm = false;
      this.patToSetDate = {};
    },
    changeSurveyDate(pat) {
      this.patToSetDate = pat;
      this.showSetDateForm = true;
    },
    setPatDate() {
      let bodyParams = {
        date: getOnlyDate(this.patToSetDate.date_date),
      };
      return patsService
        .setSurveyDate(this.patToSetDate.id, bodyParams)
        .then((response) => {
          if (!response) {
            this.patToSetDate.date_date = new Date(
              `${this.patToSetDate.date} 00:00`
            );
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao ao alterar a data",
              life: 3000,
            });
          } else {
            this.patToSetDate.date = bodyParams.date;
            this.$toast.add({
              severity: "success",
              summary: "Data alterada",
              detail: "A data foi alterada com sucesso",
              life: 3000,
            });
          }

          this.closeSetDateForm();
        });
    },
    confirmClosePat(pat) {
      let message = `Tem a certeza que pretende concluir ${pat.service_type}
      do Pat ${pat.id} - ${pat.name}?`;
      this.$confirm.require({
        header: `Concluir ${pat.service_type}`,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-outlined p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-outlined p-button-danger p-button p-component",
        accept: () => {
          this.closePat(pat);
        },
      });
    },
    closePat(pat) {
      return patsService.closePat(pat.id).then((response) => {
        if (!response || response.closed != 1) {
          return this.$toast.add({
            severity: "error",
            summary: `Erro ao concluir ${pat.service_type}`,
            detail: "",
            life: 3000,
          });
        }
        pat.to_do = "Concluido";
        pat.status = "2. Realizado";

        return this.$toast.add({
          severity: "success",
          summary: `${pat.service_type} fechado`,
          detail: `${pat.service_type} com o pat ${pat.id}
            foi fechado`,
          life: 3000,
        });
      });
    },
    confirmRealizedPat(pat) {
      let message = `Tem a certeza que pretende marcar como realizado o ${pat.service_type}
      do Pat ${pat.id} - ${pat.name}?`;
      this.$confirm.require({
        header: `Marcar como realizado ${pat.service_type}`,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-text p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-text p-button-danger p-button p-component",
        accept: () => {
          this.realizadPat(pat);
        },
      });
    },
    realizadPat(pat) {
      const status = "2. Realizado";
      const areYou = (patAux) => {
        return patAux.id == pat.id;
      };

      return patsService
        .updatedStatus(pat.id, { status: status })
        .then((response) => {
          if (!response || response.status != status) {
            return this.$toast.add({
              severity: "error",
              summary: `Erro ao marcar como realizado o ${pat.service_type}`,
              detail: "",
              life: 3000,
            });
          }

          let patndex = this.patsList.findIndex(areYou);

          this.patsList[patndex].status = status;
          this.patsList[patndex].to_do = "Sem relatório";

          return this.$toast.add({
            severity: "success",
            summary: `${pat.service_type} realizado`,
            detail: `${pat.service_type} com o pat ${pat.id}
            mudou o estado para realizado`,
            life: 3000,
          });
        });
    },
    showUploadForm(pat) {
      this.showFormReport = true;
      this.reportToPat = pat;
      this.reportToPat["files"] = [];
    },
    closeResporForm() {
      this.showFormReport = false;
      this.reportToPat = {};
    },
    uploadReportSubmit() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid && this.$refs.uploadInput.files.length == 0) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.$refs.uploadInput.files.length > 0) {
          this.uploadReportFile();
        }
        if (this.reportToPat.solution != "") {
          this.setSolutions();
        }
      });
    },
    uploadReportFile() {
      let formData = new FormData();
      formData.append("file", this.$refs.uploadInput.files[0]);
      formData.append("origin", "PA");
      formData.append("origin_reference", this.reportToPat.reference);

      return filesService.addFile(formData).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir o relatório",
            life: 3000,
          });
        }

        this.reportToPat.to_do = "Por fechar";
        this.closeResporForm();

        return this.$toast.add({
          severity: "success",
          summary: "Relatório inserido",
          detail: "O relatório inserido com sucesso",
          life: 3000,
        });
      });
    },
    solutionAreRequired() {
      if (this.$refs.uploadInput.files.length == 0) {
        return true;
      }

      return false;
    },
    setSolutions() {
      return patsService
        .setSolution(this.reportToPat.id, {
          solution: this.reportToPat.solution,
        })
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o relatório",
              life: 3000,
            });
          }

          this.reportToPat.to_do = "Por fechar";
          this.closeResporForm();

          return this.$toast.add({
            severity: "success",
            summary: "Relatório inserido",
            detail: "O relatório inserido com sucesso",
            life: 3000,
          });
        });
    },
    changeSurveyResposible(pat) {
      this.patChangeSurveyResponsible.show = true;
      this.patChangeSurveyResponsible.id = pat.id;
      this.patChangeSurveyResponsible.technicianUserId = pat.technicianUserId;
      this.patChangeSurveyResponsible.pat = pat;
    },
    changeSurveyResponsibleCancel() {
      this.patChangeSurveyResponsible = {
        show: false,
        id: null,
        technicianUserId: null,
      };
    },
    changedSurveyResponsible(response) {
      if (response.status == "ok") {
        this.patChangeSurveyResponsible.pat.technicianUserId =
          response.currentResponsible.id;
        this.patChangeSurveyResponsible.pat.technician =
          response.currentResponsible.name;
      }
      this.patChangeSurveyResponsible = {
        show: false,
        id: null,
        technicianUserId: null,
      };
    },
    removelocalStorage() {
      this.nameFilterActive = null;
      localStorage.removeItem("budgetActiveFilter");
    },
    changeSurveySellerCancel() {
      this.patChangeSurveySeller = {
        show: false,
        id: null,
        sellerId: null,
      };
    },
    changedSurveySeller(response) {
      if (response.status == "ok") {
        this.patChangeSurveySeller.pat.sellerId = response.currentSeller.id;
        this.patChangeSurveySeller.pat.seller = response.currentSeller.name;
      }
      this.patChangeSurveySeller = {
        show: false,
        id: null,
        sellerId: null,
      };
    },
    changeSurveySeller(pat) {
      this.patChangeSurveySeller.show = true;
      this.patChangeSurveySeller.id = pat.id;
      this.patChangeSurveySeller.sellerId = parseInt(pat.sellerId);
      this.patChangeSurveySeller.pat = pat;
    },
  },
};
</script>
<style scoped lang="scss">
#year-selector {
  width: 60px;
}

.p-column-filter {
  width: 100%;
}
</style>
