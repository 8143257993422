import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class FilesService {
  addFile(bodyParms) {
    let headers = authHeader();
    headers['Content-Type'] = 'multipart/form-data';

    return axios
      .post(`${config["apiUrl"]}file`, bodyParms, {
        headers: headers})
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  removeFile(bodyParms) {
    return axios
      .delete(`${config["apiUrl"]}file`, {
        headers: authHeader(),
        data: bodyParms
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        // if (error.response.status == 401) {
        //   AuthService.logout();
        //   window.location = "/login";
        // }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getFileFromOriginReference(bodyParms) {
    return axios
      .get(`${config["apiUrl"]}file/fromOriginReference`, {
        headers: authHeader(),
        params: bodyParms
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        // if (error.response.status == 401) {
        //   AuthService.logout();
        //   window.location = "/login";
        // }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

}
export default new FilesService();
