<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Vendedor do Survey'"
      :modal="true"
      :closable="false"
    >
      <form
        name="SurveyChangeSurveySeller"
        class="p-col-12"
        @submit.prevent="saveSeller"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 40vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="sellerUser"
                :options="listAllSellers"
                :filter="true"
                :optionLabel="getSearchLabel"
                :optionValue="'id'"
                v-model="currentIdSeller"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('sellerUser') },
                  'form-control',
                ]"
              >
              <template #option="slotProps">
                {{ slotProps.option.name }}
              </template>
              </Dropdown>
              <label for="inputtext">Selecionar Vendedor</label>
            </span>
            <small v-if="errors.has('sellerUser')" class="p-error" role="alert">
              Vendedor é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveSeller"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import patsService from "../services/pats.service";
import prospectService from "../services/prospect.service";
import unidecode from "unidecode";
export default {
  name: "SurveyChangeSurveySeller",
  props: ["showToast", "show", "currentSellerId", "patId"],
  watch: {
    currentSellerId() {
      this.$validator.pause();
      this.$validator.reset();
      this.currentIdSeller = this.currentSellerId;
    },
  },
  data() {
    return {
      currentIdSeller: this.currentSellerId,
      listAllSellers: [],
    };
  },
  created() {
    this.getActiveSellers();
  },
  methods: {
    getActiveSellers() {
      return prospectService
        .getSellers()
        .then((response) => (this.listAllSellers = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    saveSeller() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        return patsService
          .setSurveySeller(this.patId, this.currentIdSeller)
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o vendedor do survey",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("setSurveySeller", {
                status: "error",
                currentSeller: {},
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Vendedor do survey alterado com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("setSurveySeller", {
              status: "ok",
              currentSeller: {
                id: response.seller_id,
                name: response.seller_name,
              },
            });
          });
      });
    },
    getSearchLabel(info) {
      return `${unidecode(info.name)}`
    },
  },
};
</script>
